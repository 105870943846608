/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum CustomQuoteStatus {
    ACCEPTED = 'ACCEPTED',
    REJECTED = 'REJECTED',
    DECLINED = 'DECLINED',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_STARTED = 'NOT_STARTED',
    SUBMITTED = 'SUBMITTED',
}