/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum EventType {
    ADDRESS_ADDED = 'ADDRESS_ADDED',
    ADDRESS_COULD_NOT_BE_ADDED = 'ADDRESS_COULD_NOT_BE_ADDED',
    ADDRESS_COULD_NOT_BE_EDITED = 'ADDRESS_COULD_NOT_BE_EDITED',
    ADDRESS_COULD_NOT_BE_REMOVED = 'ADDRESS_COULD_NOT_BE_REMOVED',
    ADDRESS_EDITED = 'ADDRESS_EDITED',
    ADDRESS_REMOVED = 'ADDRESS_REMOVED',
    ADDRESS_VALIDATOR_MATCH_COULD_NOT_BE_FOUND = 'ADDRESS_VALIDATOR_MATCH_COULD_NOT_BE_FOUND',
    AGREEMENT_ASSIGNED = 'AGREEMENT_ASSIGNED',
    AGREEMENT_ADDED = 'AGREEMENT_ADDED',
    AGREEMENT_EDITED = 'AGREEMENT_EDITED',
    AGREEMENT_DELETED = 'AGREEMENT_DELETED',
    AGREEMENT_DISABLED = 'AGREEMENT_DISABLED',
    AGREEMENT_ENABLED = 'AGREEMENT_ENABLED',
    AGREEMENT_COULD_NOT_BE_ASSIGNED = 'AGREEMENT_COULD_NOT_BE_ASSIGNED',
    AGREEMENT_COULD_NOT_BE_ADDED = 'AGREEMENT_COULD_NOT_BE_ADDED',
    AGREEMENT_COULD_NOT_BE_EDITED = 'AGREEMENT_COULD_NOT_BE_EDITED',
    AGREEMENT_COULD_NOT_BE_DELETED = 'AGREEMENT_COULD_NOT_BE_DELETED',
    AGREEMENT_COULD_NOT_BE_DISABLED = 'AGREEMENT_COULD_NOT_BE_DISABLED',
    AGREEMENT_COULD_NOT_BE_ENABLED = 'AGREEMENT_COULD_NOT_BE_ENABLED',
    API_KEY_COULD_NOT_BE_DISABLED = 'API_KEY_COULD_NOT_BE_DISABLED',
    API_KEY_COULD_NOT_BE_ENABLED = 'API_KEY_COULD_NOT_BE_ENABLED',
    API_KEY_COULD_NOT_BE_GENERATED = 'API_KEY_COULD_NOT_BE_GENERATED',
    API_KEY_COULD_NOT_BE_REMOVED = 'API_KEY_COULD_NOT_BE_REMOVED',
    API_KEY_DISABLED = 'API_KEY_DISABLED',
    API_KEY_ENABLED = 'API_KEY_ENABLED',
    API_KEY_GENERATED = 'API_KEY_GENERATED',
    API_KEY_REMOVED = 'API_KEY_REMOVED',
    CREDIT_APPLICATION_SUBMITTED = 'CREDIT_APPLICATION_SUBMITTED',
    CREDIT_APPLICATION_SCORE_UPDATED = 'CREDIT_APPLICATION_SCORE_UPDATED',
    CREDIT_APPLICATION_COULD_NOT_BE_SUBMITTED = 'CREDIT_APPLICATION_COULD_NOT_BE_SUBMITTED',
    CREDIT_APPLICATION_SCORE_COULD_NOT_BE_UPDATED = 'CREDIT_APPLICATION_SCORE_COULD_NOT_BE_UPDATED',
    CURRENCY_EXCHANGE_RATE_CHANGED = 'CURRENCY_EXCHANGE_RATE_CHANGED',
    CURRENCY_EXCHANGE_RATE_COULD_NOT_BE_CHANGED = 'CURRENCY_EXCHANGE_RATE_COULD_NOT_BE_CHANGED',
    DEFAULT_BILLING_USER_ADDRESS_ADDED = 'DEFAULT_BILLING_USER_ADDRESS_ADDED',
    DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_ADDED = 'DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_ADDED',
    DEFAULT_BILLING_USER_ADDRESS_REMOVED = 'DEFAULT_BILLING_USER_ADDRESS_REMOVED',
    DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_REMOVED = 'DEFAULT_BILLING_USER_ADDRESS_COULD_NOT_BE_REMOVED',
    DEFAULT_SHIPPING_USER_ADDRESS_ADDED = 'DEFAULT_SHIPPING_USER_ADDRESS_ADDED',
    DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_ADDED = 'DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_ADDED',
    DEFAULT_SHIPPING_USER_ADDRESS_REMOVED = 'DEFAULT_SHIPPING_USER_ADDRESS_REMOVED',
    DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_REMOVED = 'DEFAULT_SHIPPING_USER_ADDRESS_COULD_NOT_BE_REMOVED',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_ADDED = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_ADDED',
    INVOICE_CONSOLIDATION_ADDED = 'INVOICE_CONSOLIDATION_ADDED',
    INVOICE_CONSOLIDATION_GENERATED = 'INVOICE_CONSOLIDATION_GENERATED',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_GENERATED = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_GENERATED',
    INVOICE_CONSOLIDATION_SENT = 'INVOICE_CONSOLIDATION_SENT',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_SENT = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_SENT',
    INVOICE_CONSOLIDATION_RESENT = 'INVOICE_CONSOLIDATION_RESENT',
    INVOICE_CONSOLIDATION_COULD_NOT_BE_RESENT = 'INVOICE_CONSOLIDATION_COULD_NOT_BE_RESENT',
    ORDER_ABORTED = 'ORDER_ABORTED',
    ORDER_REACTIVATED = 'ORDER_REACTIVATED',
    ORDER_ACCEPTED = 'ORDER_ACCEPTED',
    ORDER_BILLING_ADDRESS_CHANGED = 'ORDER_BILLING_ADDRESS_CHANGED',
    ORDER_BILLING_ADDRESS_COULD_NOT_BE_CHANGED = 'ORDER_BILLING_ADDRESS_COULD_NOT_BE_CHANGED',
    ORDER_BILLING_CURRENCY_CHANGED = 'ORDER_BILLING_CURRENCY_CHANGED',
    ORDER_BILLING_CURRENCY_COULD_NOT_BE_CHANGED = 'ORDER_BILLING_CURRENCY_COULD_NOT_BE_CHANGED',
    ORDER_BILLING_PO_CHANGED = 'ORDER_BILLING_PO_CHANGED',
    ORDER_BILLING_PO_COULD_NOT_BE_CHANGED = 'ORDER_BILLING_PO_COULD_NOT_BE_CHANGED',
    ORDER_BILL_OF_LADING_ADDED = 'ORDER_BILL_OF_LADING_ADDED',
    ORDER_BILL_OF_LADING_COULD_NOT_BE_ADDED = 'ORDER_BILL_OF_LADING_COULD_NOT_BE_ADDED',
    ORDER_BILL_OF_LADING_COULD_NOT_BE_GENERATED = 'ORDER_BILL_OF_LADING_COULD_NOT_BE_GENERATED',
    ORDER_BILL_OF_LADING_GENERATED = 'ORDER_BILL_OF_LADING_GENERATED',
    ORDER_LABEL_ADDED = 'ORDER_LABEL_ADDED',
    ORDER_LABEL_COULD_NOT_BE_ADDED = 'ORDER_LABEL_COULD_NOT_BE_ADDED',
    ORDER_LABEL_COULD_NOT_BE_GENERATED = 'ORDER_LABEL_COULD_NOT_BE_GENERATED',
    ORDER_LABEL_GENERATED = 'ORDER_LABEL_GENERATED',
    ORDER_LOAD_TENDER_ADDED = 'ORDER_LOAD_TENDER_ADDED',
    ORDER_LOAD_TENDER_COULD_NOT_BE_ADDED = 'ORDER_LOAD_TENDER_COULD_NOT_BE_ADDED',
    ORDER_LOAD_TENDER_COULD_NOT_BE_GENERATED = 'ORDER_LOAD_TENDER_COULD_NOT_BE_GENERATED',
    ORDER_LOAD_TENDER_GENERATED = 'ORDER_LOAD_TENDER_GENERATED',
    ORDER_COMMERCIAL_INVOICE_ADDED = 'ORDER_COMMERCIAL_INVOICE_ADDED',
    ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_ADDED = 'ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_ADDED',
    ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_GENERATED = 'ORDER_COMMERCIAL_INVOICE_COULD_NOT_BE_GENERATED',
    ORDER_COMMERCIAL_INVOICE_GENERATED = 'ORDER_COMMERCIAL_INVOICE_GENERATED',
    ORDER_CERTIFICATE_OF_INSURANCE_ADDED = 'ORDER_CERTIFICATE_OF_INSURANCE_ADDED',
    ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_ADDED = 'ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_ADDED',
    ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_GENERATED = 'ORDER_CERTIFICATE_OF_INSURANCE_COULD_NOT_BE_GENERATED',
    ORDER_CERTIFICATE_OF_INSURANCE_GENERATED = 'ORDER_CERTIFICATE_OF_INSURANCE_GENERATED',
    ORDER_BOOKED = 'ORDER_BOOKED',
    ORDER_CANCELLED = 'ORDER_CANCELLED',
    ORDER_THREE_PL_CHANGED = 'ORDER_THREE_PL_CHANGED',
    ORDER_THREE_PL_COULD_NOT_BE_CHANGED = 'ORDER_THREE_PL_COULD_NOT_BE_CHANGED',
    ORDER_CLIENT_COMPLETED = 'ORDER_CLIENT_COMPLETED',
    ORDER_THREE_PL_COMPLETED = 'ORDER_THREE_PL_COMPLETED',
    ORDER_THREE_PL_AGENT_ASSIGNED = 'ORDER_THREE_PL_AGENT_ASSIGNED',
    ORDER_THREE_PL_AGENT_COULD_NOT_BE_ASSIGNED = 'ORDER_THREE_PL_AGENT_COULD_NOT_BE_ASSIGNED',
    ORDER_CLOSED = 'ORDER_CLOSED',
    ORDER_COULD_NOT_BE_ABORTED = 'ORDER_COULD_NOT_BE_ABORTED',
    ORDER_COULD_NOT_BE_REACTIVATED = 'ORDER_COULD_NOT_BE_REACTIVATED',
    ORDER_COULD_NOT_BE_ACCEPTED = 'ORDER_COULD_NOT_BE_ACCEPTED',
    ORDER_COULD_NOT_BE_BOOKED = 'ORDER_COULD_NOT_BE_BOOKED',
    ORDER_COULD_NOT_BE_CANCELLED = 'ORDER_COULD_NOT_BE_CANCELLED',
    ORDER_COULD_NOT_BE_CLOSED = 'ORDER_COULD_NOT_BE_CLOSED',
    ORDER_COULD_NOT_BE_DECLINED = 'ORDER_COULD_NOT_BE_DECLINED',
    ORDER_COULD_NOT_BE_DISPATCHED = 'ORDER_COULD_NOT_BE_DISPATCHED',
    ORDER_COULD_NOT_BE_DRAFTED = 'ORDER_COULD_NOT_BE_DRAFTED',
    ORDER_COULD_NOT_BE_EDITED = 'ORDER_COULD_NOT_BE_EDITED',
    ORDER_COULD_NOT_BE_EXPIRED = 'ORDER_COULD_NOT_BE_EXPIRED',
    ORDER_COULD_NOT_BE_INVOICED = 'ORDER_COULD_NOT_BE_INVOICED',
    ORDER_COULD_NOT_BE_MARKED_AS_REVISED = 'ORDER_COULD_NOT_BE_MARKED_AS_REVISED',
    ORDER_COULD_NOT_BE_OPENED = 'ORDER_COULD_NOT_BE_OPENED',
    ORDER_PRICE_COULD_NOT_BE_REVIEWED = 'ORDER_PRICE_COULD_NOT_BE_REVIEWED',
    ORDER_COULD_NOT_BE_REJECTED = 'ORDER_COULD_NOT_BE_REJECTED',
    ORDER_COULD_NOT_BE_SUBMITTED = 'ORDER_COULD_NOT_BE_SUBMITTED',
    ORDER_CUSTOM_QUOTE_ADDED = 'ORDER_CUSTOM_QUOTE_ADDED',
    ORDER_CUSTOM_QUOTE_COULD_NOT_BE_ADDED = 'ORDER_CUSTOM_QUOTE_COULD_NOT_BE_ADDED',
    ORDER_DECLINED = 'ORDER_DECLINED',
    ORDER_DISPATCHED = 'ORDER_DISPATCHED',
    ORDER_DOCUMENT_ADDED = 'ORDER_DOCUMENT_ADDED',
    ORDER_DOCUMENT_COULD_NOT_BE_ADDED = 'ORDER_DOCUMENT_COULD_NOT_BE_ADDED',
    ORDER_DOCUMENT_COULD_NOT_BE_FETCHED = 'ORDER_DOCUMENT_COULD_NOT_BE_FETCHED',
    ORDER_DOCUMENT_COULD_NOT_BE_REMOVED = 'ORDER_DOCUMENT_COULD_NOT_BE_REMOVED',
    ORDER_DOCUMENT_FETCHED = 'ORDER_DOCUMENT_FETCHED',
    ORDER_DOCUMENT_REMOVED = 'ORDER_DOCUMENT_REMOVED',
    ORDER_DRAFTED = 'ORDER_DRAFTED',
    ORDER_DRAFT_COULD_NOT_BE_SUBMITTED = 'ORDER_DRAFT_COULD_NOT_BE_SUBMITTED',
    ORDER_DRAFT_SUBMITTED = 'ORDER_DRAFT_SUBMITTED',
    ORDER_EDITED = 'ORDER_EDITED',
    ORDER_EXPIRED = 'ORDER_EXPIRED',
    ORDER_INVOICED = 'ORDER_INVOICED',
    ORDER_MARKED_AS_REVISED = 'ORDER_MARKED_AS_REVISED',
    ORDER_OPENED = 'ORDER_OPENED',
    ORDER_PRIVATE_NOTES_COULD_NOT_BE_SAVED = 'ORDER_PRIVATE_NOTES_COULD_NOT_BE_SAVED',
    ORDER_PRIVATE_NOTES_SAVED = 'ORDER_PRIVATE_NOTES_SAVED',
    ORDER_PROOF_OF_DELIVERY_ADDED = 'ORDER_PROOF_OF_DELIVERY_ADDED',
    ORDER_TRANSPORT_INVOICE_DOCUMENT_ADDED = 'ORDER_TRANSPORT_INVOICE_DOCUMENT_ADDED',
    ORDER_INVOICE_SUPPORT_DOCUMENT_ADDED = 'ORDER_INVOICE_SUPPORT_DOCUMENT_ADDED',
    ORDER_PROOF_OF_DELIVERY_COULD_NOT_BE_ADDED = 'ORDER_PROOF_OF_DELIVERY_COULD_NOT_BE_ADDED',
    ORDER_TRANSPORT_INVOICE_DOCUMENT_COULD_NOT_BE_ADDED = 'ORDER_TRANSPORT_INVOICE_DOCUMENT_COULD_NOT_BE_ADDED',
    ORDER_INVOICE_SUPPORT_DOCUMENT_COULD_NOT_BE_ADDED = 'ORDER_INVOICE_SUPPORT_DOCUMENT_COULD_NOT_BE_ADDED',
    ORDER_INVOICE_CSV_BATCH_ADDED = 'ORDER_INVOICE_CSV_BATCH_ADDED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_ADDED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_ADDED',
    ORDER_INVOICE_CSV_BATCH_EDITED = 'ORDER_INVOICE_CSV_BATCH_EDITED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_EDITED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_EDITED',
    ORDER_INVOICE_CSV_BATCH_APPROVED = 'ORDER_INVOICE_CSV_BATCH_APPROVED',
    ORDER_INVOICE_CSV_BATCH_REJECTED = 'ORDER_INVOICE_CSV_BATCH_REJECTED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_APPROVED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_APPROVED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_REJECTED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_REJECTED',
    ORDER_INVOICE_CSV_BATCH_PROCESSED = 'ORDER_INVOICE_CSV_BATCH_PROCESSED',
    ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_PROCESSED = 'ORDER_INVOICE_CSV_BATCH_COULD_NOT_BE_PROCESSED',
    ORDER_QUOTE_COULD_NOT_BE_SELECTED = 'ORDER_QUOTE_COULD_NOT_BE_SELECTED',
    ORDER_QUOTE_SELECTED = 'ORDER_QUOTE_SELECTED',
    ORDER_PENDING_PRICE_REVIEW_EDITED = 'ORDER_PENDING_PRICE_REVIEW_EDITED',
    ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_EDITED = 'ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_EDITED',
    ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_REJECTED = 'ORDER_PENDING_PRICE_REVIEW_COULD_NOT_BE_REJECTED',
    ORDER_PENDING_PRICE_REVIEW_REJECTED = 'ORDER_PENDING_PRICE_REVIEW_REJECTED',
    ORDER_PRICE_REVIEWED = 'ORDER_PRICE_REVIEWED',
    ORDER_QUOTE_CHANGED = 'ORDER_QUOTE_CHANGED',
    ORDER_QUOTE_COULD_NOT_BE_CHANGED = 'ORDER_QUOTE_COULD_NOT_BE_CHANGED',
    ORDER_QUOTE_STATUS_ADDED = 'ORDER_QUOTE_STATUS_ADDED',
    ORDER_QUOTE_STATUS_COULD_NOT_BE_ADDED = 'ORDER_QUOTE_STATUS_COULD_NOT_BE_ADDED',
    ORDER_QUOTE_STATUS_DELETED = 'ORDER_QUOTE_STATUS_DELETED',
    ORDER_QUOTE_STATUS_COULD_NOT_BE_DELETED = 'ORDER_QUOTE_STATUS_COULD_NOT_BE_DELETED',
    ORDER_REJECTED = 'ORDER_REJECTED',
    ORDER_RFQ_STATUS_CHANGED = 'ORDER_RFQ_STATUS_CHANGED',
    ORDER_RFQ_STATUS_COULD_NOT_BE_CHANGED = 'ORDER_RFQ_STATUS_COULD_NOT_BE_CHANGED',
    ORDER_RFQ_COVERAGE_CHANGED = 'ORDER_RFQ_COVERAGE_CHANGED',
    ORDER_RFQ_COVERAGE_COULD_NOT_BE_CHANGED = 'ORDER_RFQ_COVERAGE_COULD_NOT_BE_CHANGED',
    ORGANIZATION_SETTINGS_CHANGED = 'ORGANIZATION_SETTINGS_CHANGED',
    ORGANIZATION_SETTINGS_COULD_NOT_BE_CHANGED = 'ORGANIZATION_SETTINGS_COULD_NOT_BE_CHANGED',
    ORDER_SHIPMENT_CARRIER_NUMBER_COULD_NOT_BE_SET = 'ORDER_SHIPMENT_CARRIER_NUMBER_COULD_NOT_BE_SET',
    ORDER_SHIPMENT_CARRIER_NUMBER_SET = 'ORDER_SHIPMENT_CARRIER_NUMBER_SET',
    ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_COULD_NOT_BE_SET = 'ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_COULD_NOT_BE_SET',
    ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_SET = 'ORDER_SHIPMENT_ESTIMATED_DELIVERY_DATE_SET',
    ORDER_SHIPMENT_TRACKING_COULD_NOT_BE_ENGAGED = 'ORDER_SHIPMENT_TRACKING_COULD_NOT_BE_ENGAGED',
    ORDER_SHIPMENT_TRACKING_ENGAGED = 'ORDER_SHIPMENT_TRACKING_ENGAGED',
    ORDER_SHIPMENT_TRACKING_UPDATE_ADDED = 'ORDER_SHIPMENT_TRACKING_UPDATE_ADDED',
    ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_ADDED = 'ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_ADDED',
    ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_REMOVED = 'ORDER_SHIPMENT_TRACKING_UPDATE_COULD_NOT_BE_REMOVED',
    ORDER_SHIPMENT_TRACKING_UPDATE_REMOVED = 'ORDER_SHIPMENT_TRACKING_UPDATE_REMOVED',
    ORDER_STATUS_CHANGED = 'ORDER_STATUS_CHANGED',
    ORDER_STATUS_COULD_NOT_BE_CHANGED = 'ORDER_STATUS_COULD_NOT_BE_CHANGED',
    ORDER_SUBMITTED = 'ORDER_SUBMITTED',
    ORGANIZATION_APPROVED = 'ORGANIZATION_APPROVED',
    ORGANIZATION_COULD_NOT_BE_APPROVED = 'ORGANIZATION_COULD_NOT_BE_APPROVED',
    ORGANIZATION_COULD_NOT_BE_DEACTIVATED = 'ORGANIZATION_COULD_NOT_BE_DEACTIVATED',
    ORGANIZATION_COULD_NOT_BE_BLOCKED = 'ORGANIZATION_COULD_NOT_BE_BLOCKED',
    ORGANIZATION_COULD_NOT_BE_EVALUATED = 'ORGANIZATION_COULD_NOT_BE_EVALUATED',
    ORGANIZATION_COULD_NOT_BE_DELETED = 'ORGANIZATION_COULD_NOT_BE_DELETED',
    ORGANIZATION_COULD_NOT_BE_REGISTERED = 'ORGANIZATION_COULD_NOT_BE_REGISTERED',
    ORGANIZATION_DEACTIVATED = 'ORGANIZATION_DEACTIVATED',
    ORGANIZATION_BLOCKED = 'ORGANIZATION_BLOCKED',
    ORGANIZATION_EVALUATED = 'ORGANIZATION_EVALUATED',
    ORGANIZATION_DELETED = 'ORGANIZATION_DELETED',
    ORGANIZATION_REGISTERED = 'ORGANIZATION_REGISTERED',
    PAGE_NAVIGATION_COULD_NOT_BE_RECORDED = 'PAGE_NAVIGATION_COULD_NOT_BE_RECORDED',
    PAGE_NAVIGATION_RECORDED = 'PAGE_NAVIGATION_RECORDED',
    PREPARE_SEARCH_COULD_NOT_BE_UPDATED = 'PREPARE_SEARCH_COULD_NOT_BE_UPDATED',
    PREPARE_SEARCH_UPDATED = 'PREPARE_SEARCH_UPDATED',
    PREPARE_SEARCH_COULD_NOT_BE_CREATED = 'PREPARE_SEARCH_COULD_NOT_BE_CREATED',
    PREPARE_SEARCH_CREATED = 'PREPARE_SEARCH_CREATED',
    PICKUP_SCHEDULED = 'PICKUP_SCHEDULED',
    PICKUP_COULD_NOT_BE_SCHEDULED = 'PICKUP_COULD_NOT_BE_SCHEDULED',
    PICKUP_STATUS_UPDATED = 'PICKUP_STATUS_UPDATED',
    PICKUP_STATUS_COULD_NOT_BE_UPDATED = 'PICKUP_STATUS_COULD_NOT_BE_UPDATED',
    QUICKBOOKS_MESSAGE_ADDED = 'QUICKBOOKS_MESSAGE_ADDED',
    QUICKBOOKS_MESSAGE_COULD_NOT_BE_ADDED = 'QUICKBOOKS_MESSAGE_COULD_NOT_BE_ADDED',
    SEARCH_COULD_NOT_BE_CREATED = 'SEARCH_COULD_NOT_BE_CREATED',
    SEARCH_CREATED = 'SEARCH_CREATED',
    SEARCH_RFQ_PROGRESS_UPDATED = 'SEARCH_RFQ_PROGRESS_UPDATED',
    USER_ACTIVATED = 'USER_ACTIVATED',
    USER_AUTHENTICATED = 'USER_AUTHENTICATED',
    USER_AVATAR_CHANGED = 'USER_AVATAR_CHANGED',
    USER_AVATAR_COULD_NOT_BE_CHANGED = 'USER_AVATAR_COULD_NOT_BE_CHANGED',
    USER_CONFIRMATION_CODE_COULD_NOT_BE_RESENT = 'USER_CONFIRMATION_CODE_COULD_NOT_BE_RESENT',
    USER_CONFIRMATION_CODE_RESENT = 'USER_CONFIRMATION_CODE_RESENT',
    USER_COULD_NOT_BE_ACTIVATED = 'USER_COULD_NOT_BE_ACTIVATED',
    USER_COULD_NOT_BE_AUTHENTICATED = 'USER_COULD_NOT_BE_AUTHENTICATED',
    USER_COULD_NOT_BE_DEACTIVATED = 'USER_COULD_NOT_BE_DEACTIVATED',
    USER_COULD_NOT_BE_DELETED = 'USER_COULD_NOT_BE_DELETED',
    USER_COULD_NOT_BE_INVITED = 'USER_COULD_NOT_BE_INVITED',
    USER_COULD_NOT_BE_LOGGED_OUT = 'USER_COULD_NOT_BE_LOGGED_OUT',
    USER_COULD_NOT_BE_REGISTERED = 'USER_COULD_NOT_BE_REGISTERED',
    USER_COULD_NOT_BE_PURGED = 'USER_COULD_NOT_BE_PURGED',
    USER_DEACTIVATED = 'USER_DEACTIVATED',
    USER_DELETED = 'USER_DELETED',
    USER_PURGED = 'USER_PURGED',
    USER_EVENT_NOTIFICATION_ADDED = 'USER_EVENT_NOTIFICATION_ADDED',
    USER_EVENT_NOTIFICATION_MARKED_AS_READ = 'USER_EVENT_NOTIFICATION_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_READ = 'USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_VIEWED = 'USER_EVENT_NOTIFICATIONS_ALL_MARKED_AS_VIEWED',
    USER_EVENT_NOTIFICATION_COULD_NOT_BE_MARKED_AS_READ = 'USER_EVENT_NOTIFICATION_COULD_NOT_BE_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_READ = 'USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_READ',
    USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_VIEWED = 'USER_EVENT_NOTIFICATIONS_COULD_NOT_BE_ALL_MARKED_AS_VIEWED',
    USER_INVITED = 'USER_INVITED',
    USER_LOGGED_OUT = 'USER_LOGGED_OUT',
    USER_PASSWORD_CHANGED = 'USER_PASSWORD_CHANGED',
    USER_PASSWORD_CHANGE_COULD_NOT_BE_REQUESTED = 'USER_PASSWORD_CHANGE_COULD_NOT_BE_REQUESTED',
    USER_PASSWORD_CHANGE_REQUESTED = 'USER_PASSWORD_CHANGE_REQUESTED',
    USER_PASSWORD_COULD_NOT_BE_CHANGED = 'USER_PASSWORD_COULD_NOT_BE_CHANGED',
    USER_PROFILE_COULD_NOT_BE_EDITED = 'USER_PROFILE_COULD_NOT_BE_EDITED',
    USER_PROFILE_EDITED = 'USER_PROFILE_EDITED',
    ORGANIZATION_COULD_NOT_BE_EDITED = 'ORGANIZATION_COULD_NOT_BE_EDITED',
    ORGANIZATION_EDITED = 'ORGANIZATION_EDITED',
    USER_REGISTERED = 'USER_REGISTERED',
    USER_SIGN_UP_CONFIRMED = 'USER_SIGN_UP_CONFIRMED',
    USER_SIGN_UP_COULD_NOT_BE_CONFIRMED = 'USER_SIGN_UP_COULD_NOT_BE_CONFIRMED',
    EDI_ORDER_ACCEPTED = 'EDI_ORDER_ACCEPTED',
    EDI_ORDER_ADDED = 'EDI_ORDER_ADDED',
    EDI_ORDER_CANCELLED = 'EDI_ORDER_CANCELLED',
    EDI_ORDER_DECLINED = 'EDI_ORDER_DECLINED',
    EDI_ORDER_EXPIRED = 'EDI_ORDER_EXPIRED',
    EDI_ORDER_INVOICE_ADDED = 'EDI_ORDER_INVOICE_ADDED',
    EDI_ORDER_REVISION_ADDED = 'EDI_ORDER_REVISION_ADDED',
    EDI_ORDER_TRACKING_UPDATE_ADDED = 'EDI_ORDER_TRACKING_UPDATE_ADDED',
    EDI_ORDER_TRANSFERRED = 'EDI_ORDER_TRANSFERRED',
    ORDER_SHIPMENT_AND_PICKUP_COULD_NOT_BE_CANCELLED = 'ORDER_SHIPMENT_AND_PICKUP_COULD_NOT_BE_CANCELLED',
    ORDER_SHIPMENT_AND_PICKUP_CANCELLED = 'ORDER_SHIPMENT_AND_PICKUP_CANCELLED',
    ORDER_INVOICE_STATUS_CHANGED = 'ORDER_INVOICE_STATUS_CHANGED',
    ORDER_INVOICE_STATUS_COULD_NOT_BE_CHANGED = 'ORDER_INVOICE_STATUS_COULD_NOT_BE_CHANGED',
    ORDER_INVOICE_EDITED = 'ORDER_INVOICE_EDITED',
    ORDER_INVOICE_COULD_NOT_BE_EDITED = 'ORDER_INVOICE_COULD_NOT_BE_EDITED',
    ORDER_INVOICE_PAYMENT_ADDED = 'ORDER_INVOICE_PAYMENT_ADDED',
    ORDER_INVOICE_SENT = 'ORDER_INVOICE_SENT',
    ORDER_INVOICE_COULD_NOT_BE_SENT = 'ORDER_INVOICE_COULD_NOT_BE_SENT',
    ORDER_INVOICE_GENERATED = 'ORDER_INVOICE_GENERATED',
    ORDER_INVOICE_COULD_NOT_BE_GENERATED = 'ORDER_INVOICE_COULD_NOT_BE_GENERATED',
    INVOICE_EMAIL_SENT = 'INVOICE_EMAIL_SENT',
    INVOICE_EMAIL_SENT_FAILED = 'INVOICE_EMAIL_SENT_FAILED',
    INVOICE_CONSOLIDATION_EMAIL_SENT = 'INVOICE_CONSOLIDATION_EMAIL_SENT',
    INVOICE_CONSOLIDATION_EMAIL_SENT_FAILED = 'INVOICE_CONSOLIDATION_EMAIL_SENT_FAILED',
    STATEMENT_OF_ACCOUNT_GENERATED = 'STATEMENT_OF_ACCOUNT_GENERATED',
    STATEMENT_OF_ACCOUNT_COULD_NOT_BE_GENERATED = 'STATEMENT_OF_ACCOUNT_COULD_NOT_BE_GENERATED',
    STATEMENT_OF_ACCOUNT_SENT = 'STATEMENT_OF_ACCOUNT_SENT',
    STATEMENT_OF_ACCOUNT_COULD_NOT_BE_SENT = 'STATEMENT_OF_ACCOUNT_COULD_NOT_BE_SENT',
    ORDER_INVOICE_RESENT = 'ORDER_INVOICE_RESENT',
    ORDER_INVOICE_COULD_NOT_BE_RESENT = 'ORDER_INVOICE_COULD_NOT_BE_RESENT',
    MARKETPLACE_STEP_NAVIGATION_RECORDED = 'MARKETPLACE_STEP_NAVIGATION_RECORDED',
    MARKETPLACE_STEP_NAVIGATION_COULD_NOT_BE_RECORDED = 'MARKETPLACE_STEP_NAVIGATION_COULD_NOT_BE_RECORDED',
    INVOICE_VOIDED = 'INVOICE_VOIDED',
    INVOICE_DELETED = 'INVOICE_DELETED',
    INVOICE_VOIDED_FAILED = 'INVOICE_VOIDED_FAILED',
    INVOICE_DELETED_FAILED = 'INVOICE_DELETED_FAILED',
    ORGANIZATION_PERMISSIONS_CHANGED = 'ORGANIZATION_PERMISSIONS_CHANGED',
    ORGANIZATION_PERMISSIONS_COULD_NOT_BE_CHANGED = 'ORGANIZATION_PERMISSIONS_COULD_NOT_BE_CHANGED',
    TRACKING_CSV_BATCH_ADDED = 'TRACKING_CSV_BATCH_ADDED',
    TRACKING_CSV_BATCH_COULD_NOT_BE_ADDED = 'TRACKING_CSV_BATCH_COULD_NOT_BE_ADDED',
    TRACKING_CSV_DOCUMENT_ADDED = 'TRACKING_CSV_DOCUMENT_ADDED',
    TRACKING_CSV_DOCUMENT_COULD_NOT_BE_ADDED = 'TRACKING_CSV_DOCUMENT_COULD_NOT_BE_ADDED',
    PRICE_REVIEW_DELETED = 'PRICE_REVIEW_DELETED',
    PRICE_REVIEW_DELETED_FAILED = 'PRICE_REVIEW_DELETED_FAILED',
    BATCH_SHIPMENT_STARTED = 'BATCH_SHIPMENT_STARTED',
    BATCH_SHIPMENT_PROCESSED = 'BATCH_SHIPMENT_PROCESSED',
    ADDRESS_VALIDATION = 'ADDRESS_VALIDATION',
}